
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useEffect } from 'react';
import { Spinner, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Route } from 'constants/routes';
import { useAuth } from 'providers/UserProvider';
const FrontPage = () => {
    const router = useRouter();
    const { userData, isFirebaseInitializing } = useAuth();
    useEffect(() => {
        if (isFirebaseInitializing) {
            return;
        }
        void router.replace(Route.VenueLandingPage());
    }, [router, isFirebaseInitializing, userData]);
    return (<Stack height="100vh" justify="center" align="center">
      <Spinner size="xl"/>
    </Stack>);
};
export default FrontPage;

    async function __Next_Translate__getStaticProps__1924c5583bf__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1924c5583bf__ as getStaticProps }
  